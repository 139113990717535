.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.wrapper {
  padding: 5px 20px;
}

.wrapper fieldset {
  margin: 20px 0;
}

ul.Answer {
  list-style-type: none;

}

.custom-calendar {
  box-shadow: 0 1em 3em rgba(156, 136, 255,0.2);
}

.custom-today-day {
  color: #e67e22 !important;
  border: 1px solid #e67e22 !important;
}

.custom-today-day::after {
  visibility: hidden; /* hide small border under the text */
}

.status0:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(245, 5, 5, 0.7) !important;
}

.status1:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(219, 145, 60, 0.7) !important;
}

.status2:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(228, 231, 36, 0.7) !important;
}
.status3:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(34, 235, 16, 0.7) !important;
}
.status4:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(9, 13, 240, 0.7) !important;
}
.status5:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(52, 73, 94, 0.7) !important;
}


.App-statusMessage {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
}